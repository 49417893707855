<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: Aidam_Bo
 * @Date: 2023-04-16 22:30:02
 * @LastEditors: Aidam_Bo
 * @LastEditTime: 2023-04-23 21:23:33
-->
<template>
  <div class="projectContentContainier">
    <!-- <div v-html="article"></div> -->
    <iframe
      :src="contentUrl"
      frameborder="0"
      class="content"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    ></iframe>
  </div>
</template>
<script setup>
// import article from '@/articles/Kumu Lectra Sand Clock/1922/index.html';
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
const Store = useStore();
const router = useRouter();
const route = useRoute();
const id = route.params.id;

const goBack = () => {
  router.go(-1);
};

const project = Store.state.picWallImgList.filter(item => item.id === id)[0];

let contentUrl = project.h5_url;
</script>
<style lang="scss" scoped>
$dark-color: rgba(20, 26, 34, 0.9);
div.projectContentContainier {
    width: inherit;
    height: inherit;
  //background: $dark-color;
  background: transparent;
}
.content {
  width: 100%;
  height: 100%;
}
</style>